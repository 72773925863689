import React from "react";
import { useEffect, useState } from "react";
import "./App.css";
// import fetch from "node-fetch";
import { Adventure } from "./Types";
import { Adventures } from "./components/Adventures";

function App() {
  const [adventureList, setAdventureList] = useState<Adventure[]>([]);

  useEffect(() => {
    fetch("/api/adventures")
      .then((res) => res.json())
      .then((list) => {
        setAdventureList(list as Adventure[]);
      });
  }, []);
  return (
    <div className="App">
      <Adventures adventureList={adventureList} />
    </div>
  );
}

export default App;
