import React from "react";
import { Adventure } from "../Types";
import "./adventure-tile.css";

export const AdventureTile = ({
  adventure,
  selected,
  onClick,
}: {
  adventure: Adventure;
  selected?: boolean;
  onClick?: () => void;
}) => {
  return (
    <div
      className={`adventure-tile ${selected ? "selected" : "base"}`}
      onClick={onClick}
      style={{
        backgroundImage: `url(${adventure.images[1].url})`,
      }}
    >
      <div className="tile-text">{adventure.name}</div>
    </div>
  );
};
