import React from "react";
import ReactHtmlParser from "react-html-parser";
import { useState, useEffect } from "react";
import { Duration } from "luxon";
import { Adventure, Episode } from "../Types";
import "./adventure-details.css";

export const AdventureDetails = ({ adventure }: { adventure: Adventure }) => {
  const [selectedEpisode, setSelectedEpisode] = useState<Episode>(
    adventure.episodes[0]
  );

  useEffect(() => {
    setSelectedEpisode(adventure.episodes[0]);
  }, [adventure]);

  const toTime = (timeInMillis: number): string => {
    const duration = Duration.fromMillis(timeInMillis);
    return duration.toFormat("hh:mm:ss");
  };
  return (
    <div className="adventure-details">
      <div className="episode-select-container">
        {adventure.episodes.map((ep) => {
          return (
            <button
              className={`episode-select-btn ${
                ep.id === selectedEpisode.id ? "selected" : ""
              }`}
              onClick={() => setSelectedEpisode(ep)}
            >
              Del {ep.part_number}
            </button>
          );
        })}
      </div>
      <div className="details-grid">
        <div>
          <h2>{adventure.name}</h2>
        </div>
        <div>
          <p>Total speltid: {toTime(adventure.playtime)}</p>
          <p>Antal avsnitt: {adventure.episodes.length}</p>
        </div>
        <div>
          <h3>{selectedEpisode.name}</h3>
          <p>Speltid: {toTime(selectedEpisode.duration_ms)}</p>
          <p>Releasedatum: {selectedEpisode.release_date}</p>
          <div style={{ marginTop: "2.5rem", height: "55px" }}>
            <a
              className="spotify-link"
              href={selectedEpisode.external_urls.spotify}
              target="_blank"
              rel="noreferrer"
            >
              LISTEN ON SPOTIFY
            </a>
          </div>
        </div>
        <div>{ReactHtmlParser(selectedEpisode.html_description)}</div>
      </div>
    </div>
  );
};
