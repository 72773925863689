import React from "react";
import { useState } from "react";
import { Adventure } from "../Types";
import { AdventureTile } from "./AdventureTile";
import "./adventures.css";
import { AdventureDetails } from "./AdventureDetails";

export const Adventures = ({
  adventureList,
}: {
  adventureList: Adventure[];
}) => {
  const [selectedAdventure, setSelectedAdventure] = useState<
    Adventure | undefined
  >(undefined);

  const toggleSelected = (adv: Adventure) => {
    return () => {
      if (selectedAdventure?.name === adv.name) {
        setSelectedAdventure(undefined);
      } else {
        setSelectedAdventure(adv);
      }
    };
  };

  return (
    <div className="adventures-container">
      <div className="adventure-list">
        {adventureList.map((adventure) => (
          <AdventureTile
            key={adventure.name}
            adventure={adventure}
            selected={selectedAdventure?.name === adventure.name}
            onClick={toggleSelected(adventure)}
          />
        ))}
      </div>
      <div
        className={`adventure-details-container ${
          selectedAdventure === undefined ? "hidden" : ""
        }`}
      >
        {selectedAdventure !== undefined && (
          <AdventureDetails adventure={selectedAdventure} />
        )}
      </div>
    </div>
  );
};
